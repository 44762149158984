<!-- eslint-disable eqeqeq -->
<template>
  <div>
    <mds-loader
      v-if="showMainLoader"
      aria-label="Medium Loader"
    />
    <mds-layout-grid v-else>
      <mds-row>
        <mds-col :cols="1" />
        <mds-col
          :cols="10"
          :class="readOnly ? 'read-only' : ''"
        >
          <div>
            <mds-button-group
              :content="buttonGroupContent"
              aria-label="View by Super Sector"
              style="margin: 10px;"
              @mds-button-group-item-active="setActiveItem"
            />
            <div v-if="buttonGroupContent[0].active">
              <mds-button
                v-for="(button, index) in weekdayDataset"
                :key="button.text"
                :variation="button.enabled === true ? 'primary' : 'secondary'"
                :style="index === 0 ? 'margin: 10px;' : 'margin-right: 10px'"
                @click="selectWeekday($event, button)"
              >
                {{ button.text }}
              </mds-button>
              <mds-fieldset
                horizontal
                style="margin-left: 10px; margin-top: 20px;"
              >
                <mds-checkbox
                  id="default-trigger"
                  v-model="intraday.uiValue"
                  style="margin-top: 20px;"
                  @mouseover="toggle=true"
                  @mouseleave="toggle=false"
                  @focus="toggle=true"
                  @blur="toggle=false"
                  @change="intradayChanged"
                >
                  <b>Intraday</b>
                </mds-checkbox>
                <mds-tooltip
                  v-if="isMinuteNotZero"
                  v-model="toggle"
                  triggered-by="default-trigger"
                >
                  IntraDay Minutes (other than 60) allowed only for complete hour
                </mds-tooltip>
                <mds-range-slider
                  v-model="intradayMinute.uiValue"
                  lower-label="Minutes"
                  :min="5"
                  :max="55"
                  :step="5"
                  :decimal-points="0"
                  :disabled="!intraday.uiValue"
                />
              </mds-fieldset>
            </div>
            <div v-if="buttonGroupContent[1].active">
              <div>
                <mds-table
                  style="margin-left: 10px;"
                  row-hover
                >
                  <mds-thead
                    hidden-header
                  >
                    <mds-th />
                    <mds-th />
                    <mds-th />
                  </mds-thead>
                  <mds-tbody>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(0)">
                      <mds-td>
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="dayOfMonth"
                        >
                          Day of the month
                        </mds-radio-button>
                      </mds-td>
                      <mds-td colspan="2">
                        <mds-range-slider
                          v-model="dayOfMonth.uiValue"
                          lower-label="Day"
                          :min="1"
                          :max="31"
                          :step="1"
                          :decimal-points="0"
                        />
                      </mds-td>
                    </mds-tr>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(1)">
                      <mds-td colspan="3">
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="lastDayOfMonth"
                        >
                          Last day of every month
                        </mds-radio-button>
                      </mds-td>
                    </mds-tr>
                    <mds-tr @mds-tr-multiselection-changed="handleRowSelectEvent(2)">
                      <mds-td>
                        <mds-radio-button
                          v-model="selectedRadio.uiValue"
                          value="dayOfEachWeek"
                        >
                          <mds-select
                            v-model="nthDayOfMonth.uiValue"
                            hidden-label
                            label="nthDayOfMonth"
                            :options="[
                              { text: 'First', value: '1' },
                              { text: 'Second', value: '2' },
                              { text: 'Third', value: '3' },
                              { text: 'Fourth', value: '4' },
                              { text: 'Last', value: 'L'},
                            ]"
                          />
                        </mds-radio-button>
                      </mds-td>
                      <mds-td>
                        <mds-select
                          v-model="weekdayOfMonth.uiValue"
                          hidden-label
                          label="weekdayOfMonth"
                          :options="[
                            { text: 'Sunday', value: 'SUN' },
                            { text: 'Monday', value: 'MON' },
                            { text: 'Tuesday', value: 'TUE' },
                            { text: 'Wednesday', value: 'WED' },
                            { text: 'Thusday', value: 'THU' },
                            { text: 'Friday', value: 'FRI' },
                            { text: 'Saturday', value: 'SAT' },
                          ]"
                        />
                      </mds-td>
                      <mds-td> Of each Month </mds-td>
                    </mds-tr>
                  </mds-tbody>
                </mds-table>
              </div>
            </div>
            <mds-layout-grid>
              <mds-row>
                <mds-col>
                  <mds-fieldset horizontal>
                    <mds-input
                      v-model="time.uiTime"
                      label="Time"
                      type="time"
                      style="margin: 10px"
                    />
                    <mds-select
                      v-model="timeZone.dropDownTimeZone"
                      style="margin-top: 10px;"
                      label="Timezone"
                      :options="availableTz"
                    />
                  </mds-fieldset>
                </mds-col>
              </mds-row>
              <mds-row>
                <h3 style="padding-left: 25px">
                  Max Duration
                </h3>
              </mds-row>
              <mds-row>
                <mds-col>
                  <mds-range-slider
                    v-model="duration.sliderHour"
                    lower-label="Hour"
                    :min="0"
                    :max="23"
                    :step="1"
                    :decimal-points="0"
                    style="margin-left: 10px"
                  />
                </mds-col>
                <mds-col>
                  <mds-range-slider
                    v-model="duration.sliderMinute"
                    lower-label="Minute"
                    :min="0"
                    :max="55"
                    :step="5"
                    :decimal-points="0"
                  />
                </mds-col>
              </mds-row>
            </mds-layout-grid>
          </div>
        </mds-col>
      </mds-row>
    </mds-layout-grid>
    <mds-dialog
      v-if="showValidationError"
      id="showValidationError"
      v-model="showValidationError"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="warning"
        title="Validation Error"
        persistent="true"
      >
        Please check bubble $var1 timeout settings
      </mds-alert>
    </mds-dialog>
    <mds-dialog
      v-if="maxDurationExceeded"
      id="maxDurationExceeded"
      v-model="maxDurationExceeded"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="error"
        title="Validation Error"
        persistent="true"
      >
        Max duration cannot be greater than intra day interval
      </mds-alert>
    </mds-dialog>
    <mds-dialog
      v-if="showIntradayError"
      id="showIntradayError"
      v-model="showIntradayError"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="error"
        title="Intraday Error"
        persistent="true"
      >
        IntraDay Minutes(other than 60) allowed only for complete hour.
      </mds-alert>
      <template #mds-dialog-actions-right>
        <mds-button-container right-aligned>
          <mds-button
            type="button"
            variation="secondary"
            @click="dismissIntradayError"
          >
            Dismiss
          </mds-button>
        </mds-button-container>
      </template>
    </mds-dialog>
    <mds-dialog
      v-if="errorUpdatingSchedule"
      id="errorUpdatingSchedule"
      v-model="errorUpdatingSchedule"
      title=""
      hidden-title
      width="500px"
    >
      <mds-alert
        variation="error"
        title="Validation Error"
        persistent="true"
      >
        {{ errorMsg }}
      </mds-alert>
    </mds-dialog>
  </div>
</template>

<script>
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import MdsDialog from '@mds/dialog';
import MdsForm from '@mds/form';
import MdsFieldset from '@mds/fieldset';
import MdsInput from '@mds/input';
import { mapGetters, mapActions } from 'vuex';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsSelect from '@mds/select';
import MdsRadioButton from '@mds/radio-button';
import MdsTooltip from '@mds/tooltip';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsButtonGroup from '@mds/button-group';
import MdsRangeSlider from '@mds/range-slider';
import MdsCheckbox from '@mds/checkbox';
import MdsAlert from '@mds/alert';
import axios from 'axios';
import { ZoneId, ZoneRulesProvider } from '@js-joda/core';
import timeZone from '../constants/timezone.js';

const
  HOURS_PER_DAY = 24;
const MINUTES_PER_DAY = 1440;
const SECONDS_PER_DAY = 86400;
const MILLIS_PER_SECOND = 1000;
const MILLIS_PER_MINUTE = (60 * MILLIS_PER_SECOND);
const MILLIS_PER_HOUR = (60 * MILLIS_PER_MINUTE);
const MILLIS_PER_DAY = (24 * MILLIS_PER_HOUR);
const MILLIS_PER_MONTH = (28 * MILLIS_PER_DAY);
const MILLIS_PER_YEAR = (365 * MILLIS_PER_DAY);

export default {
  name: 'WorkflowSchedule',
  components: {
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsDialog,
    MdsButton,
    MdsButtonContainer,
    MdsInput,
    MdsSelect,
    MdsFieldset,
    MdsRadioButton,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButtonGroup,
    MdsRangeSlider,
    MdsTooltip,
    MdsCheckbox,
    MdsAlert,
  },
  props: {
    workflowId: { type: String, default: '' },
    operation: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      buttonGroupContent: [
        { id: '1', text: 'Daily | Weekly', active: false },
        { id: '2', text: 'Monthly', active: false },
      ],
      weekdayDataset: [
        {
          text: 'Sun',
          value: 'SUN',
          enabled: false,
          wfValue: false,
        },
        {
          text: 'Mon',
          value: 'MON',
          enabled: true,
          wfValue: false,
        },
        {
          text: 'Tue',
          value: 'TUE',
          enabled: true,
          wfValue: false,
        },
        {
          text: 'Wed',
          value: 'WED',
          enabled: true,
          wfValue: false,
        },
        {
          text: 'Thu',
          value: 'THU',
          enabled: true,
          wfValue: false,
        },
        {
          text: 'Fri',
          value: 'FRI',
          enabled: true,
          wfValue: false,
        },
        {
          text: 'Sat',
          value: 'SAT',
          enabled: false,
          wfValue: false,
        },
      ],
      intradayMinute: {
        uiValue: 0,
        wfValue: 0,
      },
      dayOfMonth: {
        uiValue: 1,
        wfValue: 1,
      },
      rangeHour: 0,
      time: {
        wfTime: '',
        uiTime: '',
      },
      date: '',
      toggle: false,
      isMinuteNotZero: false,
      intraday: {
        uiValue: false,
        wfValue: false,
      },
      monthlyRowSelected: 0,
      workflowData: {},
      workflowParameter: {},
      cron: '',
      timeZone: {
        wfTimeZone: '',
        dropDownTimeZone: '',
      },
      duration: {
        wfHour: '',
        sliderHour: 0,
        wfMinute: '',
        sliderMinute: 0,
      },
      showValidationError: false,
      showMainLoader: false,
      showIntradayError: false,
      maxDurationExceeded: false,
      selectedRadio: {
        uiValue: '',
        wfValue: '',
      },
      nthDayOfMonth: {
        uiValue: '1',
        wfValue: '1',
      },
      weekdayOfMonth: {
        uiValue: 'SUN',
        wfValue: 'SUN',
      },
      errorUpdatingSchedule: false,
      errorMsg: '',
      availableTz: timeZone.map(tz => ({ text: tz, value: tz })),
      readOnly: false,
      manager: {},
      isInitDone: false,
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getUserWorkflows', 'getParamStatus', 'getCreateNewWorkflowDetails',
      'getCurrentWorkflowManager']),
    enableSubmitButton() {
      if (this.duration.sliderHour === 0 && this.duration.sliderMinute === 0) {
        return false;
      }
      if (this.weekdayDataset.some(weekDay => weekDay.wfValue !== weekDay.enabled)) {
        return true;
      }
      if (parseInt(this.duration.wfHour, 10) !== this.duration.sliderHour) {
        return true;
      }
      if (parseInt(this.duration.wfMinute, 10) !== this.duration.sliderMinute) {
        return true;
      }
      if (this.timeZone.wfTimeZone !== this.timeZone.dropDownTimeZone) {
        return true;
      }
      if (this.intraday.uiValue !== this.intraday.wfValue) {
        return true;
      }
      if (this.intradayMinute.uiValue !== this.intradayMinute.wfValue && this.intraday.uiValue) {
        return true;
      }
      if (this.selectedRadio.uiValue !== this.selectedRadio.wfValue) {
        return true;
      }
      if (this.dayOfMonth.uiValue !== this.dayOfMonth.wfValue) {
        return true;
      }
      if (this.nthDayOfMonth.uiValue !== this.nthDayOfMonth.wfValue) {
        return true;
      }
      if (this.weekdayOfMonth.uiValue !== this.weekdayOfMonth.wfValue) {
        return true;
      }
      return this.time.wfTime !== this.time.uiTime;
    },
    isScheduleUpdateNeeded() {
      if (this.timeZone.wfTimeZone !== this.timeZone.dropDownTimeZone) {
        return true;
      }
      if (this.weekdayDataset.some(weekDay => weekDay.wfValue !== weekDay.enabled)) {
        return true;
      }
      if (this.intraday.uiValue !== this.intraday.wfValue) {
        return true;
      }
      if (this.intradayMinute.uiValue !== this.intradayMinute.wfValue && this.intraday.uiValue) {
        return true;
      }
      if (this.selectedRadio.uiValue !== this.selectedRadio.wfValue) {
        return true;
      }
      if (this.dayOfMonth.uiValue !== this.dayOfMonth.wfValue) {
        return true;
      }
      if (this.nthDayOfMonth.uiValue !== this.nthDayOfMonth.wfValue) {
        return true;
      }
      if (this.weekdayOfMonth.uiValue !== this.weekdayOfMonth.wfValue) {
        return true;
      }
      return this.time.wfTime !== this.time.uiTime;
    },
    currentCron() {
      const [hr, min] = this.time.uiTime.split(':').map(val => parseInt(val, 10));
      if (this.buttonGroupContent[0].active) {
        const weekdaysSelected = this.weekdayDataset.filter(day => day.enabled).map(day => day.value).join(',');
        const intra = this.intraday.uiValue ? `0/${this.intradayMinute.uiValue}` : min;
        return `0 ${intra} ${hr} ? * ${weekdaysSelected} *`;
      }
      if (this.selectedRadio.uiValue === 'lastDayOfMonth') {
        return `0 ${min} ${hr} L * ? *`;
      }
      if (this.selectedRadio.uiValue === 'dayOfMonth') {
        return `0 ${min} ${hr} ${this.dayOfMonth.uiValue} * ? *`;
      }
      const dayOfEachWeek = this.nthDayOfMonth.uiValue === 'L' ? `${this.weekdayOfMonth.uiValue}L`
        : `${this.weekdayOfMonth.uiValue}#${this.nthDayOfMonth.uiValue}`;
      return `0 ${min} ${hr} ? * ${dayOfEachWeek} *`;
    },
  },
  watch: {
    enableSubmitButton(val) {
      if (val && this.isInitDone) {
        this.$emit('diagramChanged');
      }
    },
    time: {
      handler(value) {
        if (value.uiTime === '') {
          this.isMinuteNotZero = false;
        } else {
          this.isMinuteNotZero = parseInt(value.uiTime.split(':')[1], 10) !== 0;
        }
      },
      deep: true,
    },
    intradayMinute: {
      handler(value) {
        if (this.isMinuteNotZero && value.uiValue !== 60) {
          this.showIntradayError = true;
        }
      },
      deep: true,
    },
    currentCron: {
      handler(value) {
        this.manager._priv.cronExpr = value;
      },
    },
    duration: {
      handler(value) {
        this.manager.duration((value.sliderHour * MILLIS_PER_HOUR)
          + (value.sliderMinute * MILLIS_PER_MINUTE));
      },
      deep: true,
    },
    timeZone: {
      handler(value) {
        this.manager.timezone(value.dropDownTimeZone);
      },
      deep: true,
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    ...mapActions('workflowModule', ['setUserWorkflow', 'getWorkflowStatus']),
    init() {
      this.manager = this.getCurrentWorkflowManager;
      if (this.operation === 'view') {
        this.readOnly = true;
      }
      if (this.operation === 'create') {
        this.workflowData = this.getCreateNewWorkflowDetails.workflowUI;
        this.workflowParameter = this.getCreateNewWorkflowDetails.paramSet;
      } else {
        [this.workflowData] = this.getUserWorkflows.filter(wf => `${wf.id}` === `${this.workflowId}`);
        this.workflowParameter = this.getParamStatus(this.workflowId);
      }

      this.cron = this.manager._priv.cronExpr;

      this.timeZone.wfTimeZone = this.manager._priv.tz;
      this.timeZone.dropDownTimeZone = this.timeZone.wfTimeZone;
      const dur = this.manager._priv.duration;
      if (dur >= 0) {
        const hr = Math.floor(dur / MILLIS_PER_HOUR);
        const min = Math.floor((dur - (hr * MILLIS_PER_HOUR)) / MILLIS_PER_MINUTE);
        [this.duration.wfHour, this.duration.wfMinute] = [hr, min];
        [this.duration.sliderHour, this.duration.sliderMinute] = [hr, min];
      }

      const temp = this.cron.split(' ');
      const second = temp[0];
      const minute = temp[1];
      const parsedMin = temp[1].includes('/') ? temp[1].split('/')[0] : temp[1];
      const hour = temp[2];
      const dayOfMonth = temp[3];
      const month = temp[4];
      const dayOfWeek = temp[5];
      const someField = temp[6];

      // set time
      this.time.uiTime = `${hour.length < 2 ? `0${hour}` : hour}:${parsedMin.length < 2 ? `0${parsedMin}` : parsedMin}`;
      this.time.wfTime = this.time.uiTime;

      // set monthly or weekly
      if (dayOfMonth === '?' && !(dayOfWeek.includes('#') || dayOfWeek.includes('L'))) {
        this.buttonGroupContent[0].active = true;
        this.buttonGroupContent[1].active = false;
        if (minute.includes('/')) {
          this.intraday.uiValue = true;
          this.intraday.wfValue = true;
          this.intradayMinute.uiValue = parseInt(minute.split('/')[1], 10);
          this.intradayMinute.wfValue = parseInt(minute.split('/')[1], 10);
        }
        this.buttonGroupContent[0].active = true;
        if (dayOfWeek === '*') {
          this.weekdayDataset.forEach((day) => {
            day.enabled = true;
            day.wfValue = true;
            return day;
          });
        } else if (dayOfWeek.includes('-')) {
          const [startDay, endDay] = dayOfWeek.split('-');

          let enable = false;

          // Iterate through each day in the dataset
          this.weekdayDataset.forEach((day) => {
            if (day.value === startDay) {
              enable = true;
            }

            if (enable) {
              day.enabled = true;
              day.wfValue = true;
            }

            if (day.value === endDay) {
              enable = false;
            }
          });
        } else {
          this.weekdayDataset.forEach((day) => {
            day.enabled = dayOfWeek.includes(day.value);
            day.wfValue = day.enabled;
            return day;
          });
        }
      } else {
        this.buttonGroupContent[1].active = true;
        this.buttonGroupContent[0].active = false;
        if (dayOfMonth === 'L') {
          this.selectedRadio.uiValue = 'lastDayOfMonth';
        } else if (dayOfWeek.includes('#')) {
          this.selectedRadio.uiValue = 'dayOfEachWeek';
          [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue] = dayOfWeek.split('#');
          [this.weekdayOfMonth.wfValue, this.nthDayOfMonth.wfValue] = [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue];
        } else if (dayOfWeek.includes('L')) {
          this.selectedRadio.uiValue = 'dayOfEachWeek';
          [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue] = [dayOfWeek.replace('L', ''), 'L'];
          [this.weekdayOfMonth.wfValue, this.nthDayOfMonth.wfValue] = [this.weekdayOfMonth.uiValue, this.nthDayOfMonth.uiValue];
        } else {
          this.selectedRadio.uiValue = 'dayOfMonth';
          this.dayOfMonth.uiValue = parseInt(dayOfMonth, 10);
          this.dayOfMonth.wfValue = this.dayOfMonth.uiValue;
        }
        this.selectedRadio.wfValue = this.selectedRadio.uiValue;
      }
      this.isInitDone = true;
    },
    selectWeekday(event, button) {
      button.enabled = !button.enabled;
    },
    setActiveItem(event) {
      this.buttonGroupContent.forEach((item) => {
        if (item.id === event.currentTarget.id) {
          item.active = true;
          if (item.id === '2') {
            this.selectedRadio.uiValue = 'dayOfMonth';
          }
        } else {
          item.active = false;
        }
      });
    },
    makeCron() {
      const [hr, min] = this.time.uiTime.split(':').map(val => parseInt(val, 10));
      if (this.buttonGroupContent[0].active) {
        const weekdaysSelected = this.weekdayDataset.filter(day => day.enabled).map(day => day.value).join(',');
        const intra = this.intraday.uiValue ? `0/${this.intradayMinute.uiValue}` : min;
        return `0 ${intra} ${hr} ? * ${weekdaysSelected} *`;
      }
      if (this.selectedRadio.uiValue === 'lastDayOfMonth') {
        return `0 ${min} ${hr} L * ? *`;
      }
      if (this.selectedRadio.uiValue === 'dayOfMonth') {
        return `0 ${min} ${hr} ${this.dayOfMonth.uiValue} * ? *`;
      }
      const dayOfEachWeek = this.nthDayOfMonth.uiValue === 'L' ? `${this.weekdayOfMonth.uiValue}L`
        : `${this.weekdayOfMonth.uiValue}#${this.nthDayOfMonth.uiValue}`;
      return `0 ${min} ${hr} ? * ${dayOfEachWeek} *`;
    },
    async fetchWorkflow() {
      return this.getWorkflowStatus(this.workflowId).then(() => {
        // eslint-disable-next-line eqeqeq
        [this.workflowData] = this.getUserWorkflows.filter(wf => wf.id == this.workflowId);
      }).catch((error) => {
        // this.$set(this.workflowParams, index, { workFlowJobModel: { parameterSetGroupModel: { parameterSetModels: [] } } });
      });
    },
    async updateGenericSchedule() {
      const payload = { psgId: this.workflowData.psgId, cron: this.makeCron(), timeZone: this.timeZone.dropDownTimeZone };
      await axios.post(`/api/workflows/${this.workflowId}/updateSchedule`, payload)
        .then()
        .catch((error) => {
          this.errorMsg = `Error updating the schedule for workflow, Error: ${error.message}`;
          this.errorUpdatingSchedule = true;
        }).finally(() => {
        });
    },
    validateNewlyCreatedWf() {
      //
    },
    saveNewlyCreatedWf() {
      this.validateNewlyCreatedWf();
    },
    async saveSchedule() {
      if (this.operation === 'create') {
        this.saveNewlyCreatedWf();
        return;
      }
      const modifiedWfBody = JSON.parse(JSON.stringify(this.workflowData));
      modifiedWfBody.timeZone = this.timeZone.dropDownTimeZone;
      modifiedWfBody.targets.forEach((target) => {
        if (target.name === 'workflow_timeout') {
          target.timeout.params.after = `${this.duration.sliderHour}:${this.duration.sliderMinute}`;
          target.timeout.tasks.forEach((task) => {
            if (task.topic === 'workflow.stop') {
              task.props['run.result'] = `Auto-stop after ${this.duration.sliderHour}h${this.duration.sliderMinute}m`;
            }
          });
        }
      });
      delete modifiedWfBody.creationDate;
      delete modifiedWfBody.changeDate;
      delete modifiedWfBody.isEditable;
      delete modifiedWfBody.permissions;

      this.showMainLoader = true;
      this.manager._priv.cronExpr = this.makeCron();
      this.manager._priv.tz = modifiedWfBody.timeZone;
      this.manager._priv.duration = (this.duration.sliderHour * MILLIS_PER_HOUR)
        + (this.duration.sliderMinute * MILLIS_PER_MINUTE);
      this.showMainLoader = false;
    },
    getBubbleTimeouts() {
      const wfTimeouts = [];
      this.workflowData.targets.forEach((target) => {
        target.dependencies.filter(dp => dp.type === 'kaw_sub' && Object.keys(dp).includes('timeout'))
          .forEach(dp => wfTimeouts.push({ timeout: dp.timeout.params.time, name: dp.columns }));
      });
      return wfTimeouts.map((timeout) => {
        const [hour, minute] = timeout.timeout.split(':');
        return { hour, minute, name: timeout.name };
      })
        .map(time => ({ timeout: new Date('2023', '0', '1', time.hour, time.minute), name: time.name }));
    },
    async validateChanges() {
      const [hour, minute] = this.time.uiTime.split(':');
      const bubbleTimeouts = this.getBubbleTimeouts();

      const wfStartTime = new Date('2023', '0', '1', hour, minute);
      const wfEndTime = new Date('2023', '0', '1', hour, minute);
      wfEndTime.setHours(wfEndTime.getHours() + this.duration.sliderHour);
      wfEndTime.setMinutes(wfEndTime.getMinutes() + this.duration.sliderMinute);
      wfEndTime.setMinutes(wfEndTime.getMinutes() - 2);

      let validationFailed = true;
      if (this.intraday.uiValue) {
        this.maxDurationExceeded = (this.duration.sliderMinute + (this.duration.sliderHour * 60)) > this.intradayMinute.uiValue;
      }
      validationFailed = !bubbleTimeouts.every(time => time.timeout >= wfStartTime && time.timeout <= wfEndTime);

      if (this.maxDurationExceeded) {
        return;
      }
      if (validationFailed) {
        this.showValidationError = true;
      } else {
        this.saveSchedule();
      }
    },
    handleRowSelectEvent(index) {

    },
    dismissIntradayError() {
      this.intraday.uiValue = false;
      this.showIntradayError = false;
    },
    intradayChanged(value) {
      if (value && this.isMinuteNotZero && this.intradayMinute.uiValue !== 60) {
        this.showIntradayError = true;
        this.$nextTick(() => {
          this.intraday.uiValue = false;
        });
      }
    },
  },
};
</script>

<style scoped>
.read-only {
  cursor: no-drop;
  pointer-events: none !important;
  opacity: 0.5 !important;
  display: block !important;
}
</style>
