<template>
  <div class="wf-edit-action__">
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-loader
        v-if="showLoader"
        size="small"
        aria-label="Small Loader"
      />
      <div v-else>
        <h3 class="stp-table-lable">
          Choose inputs and dates.
        </h3>

        <mds-row>
          <mds-col :cols="10">
            <div class="launch-history-inputs-section">
              <mds-combo-box
                v-model="inputsArray"
                label="Inputs"
                multiple
                :data-set="wfInputs"
              />
            </div>
          </mds-col>
          <mds-col :cols="2">
            <mds-button
              icon-right="group-row"
              variation="primary"
              style="margin-top: 1.75rem; margin-left: -1.5rem"
              @click="toggleAllInputSet()"
            >
              {{ toggleSelectAllInputSetParam ? 'Select' : 'Deselect' }} All
            </mds-button>
          </mds-col>
        </mds-row>

        <mds-row>
          <mds-col :cols="10">
            <div class="launch-history-dates-section">
              <mds-row>
                <mds-col :cols="3">
                  <div>
                    <h3 class="stp-table-lable">
                      From
                    </h3>
                    <mds-date-picker
                      v-model="startDate"
                      :min-max-dates="{ min: new Date(2015, 0, 1), max: new Date(2025, 8, 15) }"
                      placeholder="m/d/yyyy"
                      label=""
                    />
                  </div>
                </mds-col>
                <mds-col :cols="3">
                  <div>
                    <h3 class="stp-table-lable">
                      To
                    </h3>
                    <mds-date-picker
                      v-model="endDate"
                      :min-max-dates="{ min: new Date(2015, 0, 1), max: new Date(2025, 8, 15) }"
                      placeholder="m/d/yyyy"
                      label=""
                    />
                  </div>
                </mds-col>
                <mds-col :cols="6">
                  <h3 class="stp-table-lable">
                    Weekdays
                  </h3>
                  <div class="launch-history-weekdays-btn-group">
                    <mds-button
                      v-for="(button, index) in weekdayDataset"
                      :key="button.text"
                      :variation="button.enabled === true ? 'primary' : 'secondary'"
                      :style="index === 0 ? 'margin: 10px;' : 'margin-right: 10px'"
                      @click="selectWeekday($event, index)"
                    >
                      {{ button.text }}
                    </mds-button>
                  </div>
                </mds-col>
              </mds-row>
            </div>
          </mds-col>
        </mds-row>

        <!-- launch-history-jobs-action-button-section -->
        <mds-row
          v-if="!isWritePermissionError"
          class=""
        >
          <mds-col>
            <div class="launch-history-add-btn">
              <mds-button
                icon-right="angle-double-right"
                variation="primary"
                :disabled="disableAddButton"
                @click="generateInputSetForHistoricalRun(inputsArray, startDate, endDate, disableAddButton)"
              >
                Add
              </mds-button>
            </div>
          </mds-col>
          <mds-col />
          <mds-col style="display: flex; justify-content: flex-end;">
            <div>
              <mds-button
                variation="primary"
                :disabled="disableLaunchButton"
                @click="submitJobs(disableLaunchButton)"
              >
                Launch
              </mds-button>
              <mds-button
                style="margin-left: 0.5rem;"
                icon-right="remove"
                variation="secondary"
                :disabled="disableClearAllButton || disableClearAllBtn"
                @click="clearInputSet()"
              >
                Clear All
              </mds-button>
            </div>
          </mds-col>
        </mds-row>

        <mds-banner
          v-if="showInputOverflowBanner"
          variation="warning"
          persistent
        >
          {{ bannerContent }}
        </mds-banner>
        <!-- launch-history-jobs-section -->
        <div class="launch-history-jobs-section">
          <mds-row>
            <mds-col :cols="10">
              <h3
                class="stp-table-lable"
                style="padding-top: 0.25rem;"
              >
                Jobs to Launch
              </h3>
            </mds-col>
            <mds-col style="display: flex; justify-content: flex-end;">
              <div>
                <mds-button
                  v-if="isWritePermissionError"
                  icon-right="remove"
                  variation="secondary"
                  @click="removeNotPermittedInputs()"
                >
                  Remove
                </mds-button>
              </div>
            </mds-col>
          </mds-row>
          <div class="launch-history-table">
            <mds-table
              v-if="totalInputSet.length > 0"
            >
              <mds-thead>
                <mds-th style="padding-left: 0.5rem">
                  Input
                </mds-th>
                <mds-th>Date</mds-th>
                <mds-th>Status</mds-th>
                <mds-th>Feed</mds-th>
                <mds-th
                  style="display: flex; justify-content: center;"
                >
                  Write permission
                </mds-th>
                <mds-th
                  right-aligned
                  style="padding-right: 5%;"
                >
                  Action
                </mds-th>
              </mds-thead>
              <mds-tbody
                class="launch-history-jobs-input-section"
              >
                <mds-tr
                  v-for="(input, index) in paginatedData"
                  :key="index"
                  :class="!input.isFeedWritePermission ? 'highlight-red' : 'highlight-green'"
                >
                  <mds-td style="padding-left: 0.5rem">
                    {{ input.inputName }}
                  </mds-td>
                  <mds-td>{{ input.inputDate }}</mds-td>
                  <mds-td>{{ input.inputStatus }}</mds-td>
                  <mds-td>{{ input.inputPublishFeedName }}</mds-td>
                  <mds-td style="display: flex; justify-content: center;">
                    {{ input.isFeedWritePermission ? 'Yes' : 'No' }}
                  </mds-td>
                  <mds-td
                    right-aligned
                    style="padding-right: 5.5% !important"
                  >
                    <mds-button
                      variation="icon-only"
                      icon="remove"
                      type="button"
                      :disabled="isWritePermissionError"
                      @click="deleteScheduledJob(index)"
                    >
                      Remove
                    </mds-button>
                  </mds-td>
                </mds-tr>
              </mds-tbody>
            </mds-table>
            <mds-loader
              v-else-if="goForLauchHistory"
              size="large"
              aria-label="Large Loader"
            />
          </div>

          <mds-pagination
            v-if="totalInputSet.length > 50"
            :total-items="totalInputSet.length"
            show-items-info
            :page-sizes="pageSize"
            @mds-pagination-page-changed="handlePageChange"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import {
  MdsTable, MdsThead, MdsTh, MdsTbody, MdsTr, MdsTd,
} from '@mds/data-table';
import { MdsButton } from '@mds/button';
import MdsBanner from '@mds/banner';
import MdsComboBox from '@mds/combo-box';
import MdsDatePicker from '@mds/date-picker';
import { MdsRow, MdsCol } from '@mds/layout-grid';
import { Promise } from 'bluebird';
import MdsPagination from '@mds/pagination';
import axios from 'axios';
import { _getSavingDataSetName, _getSaveBubbles } from '../../../scripts/utils/Manager';
import * as CONSTANTS from '../../../scripts/utils/mp/constants.es6';
import Objects, { isVoid } from '../../../scripts/utils/objects.es6';
import { getUserName, getUserCompany, getUserRoles } from '../../../../../utils/authService';

const PARAM_PREFIX_SINGLE_VAR = 'udef.var.';
const PARAM_PREFIX_DATASET = 'udef.ds.';
const DATASET_NAME = 'name';
const DATASET_SUFFIX_FEED = 'feed';
const DATASET_SUFFIX_KEY_ROOTS = 'key';
const DATASET_SUFFIX_COLS = 'cols';
const DATASET_SUFFIX_PROVIDER = 'prov';
const DATASET_SUFFIX_SOURCE = 'source';
const DATASET_SUFFIXES = [
  DATASET_SUFFIX_FEED,
  DATASET_SUFFIX_KEY_ROOTS,
  DATASET_SUFFIX_COLS,
  DATASET_SUFFIX_PROVIDER,
  DATASET_SUFFIX_SOURCE,
];
const BUBBLE_NAME = 'bubbleName';
const BUBBLE_DESCR = 'bubbleDescr';
const PARAM_SET_NAME = 'priv.name';
const PARAM_SET_DESCR = 'priv.descr';
const PARAM_SET_UUID = 'priv.uuid';

export default {
  name: 'WorkflowFormulaLaunchHistoryActions',
  components: {
    MdsBanner,
    MdsTable,
    MdsThead,
    MdsTh,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsComboBox,
    MdsDatePicker,
    MdsPagination,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: { type: Object, default: null },
    workflow: { type: Object, default: null },
  },
  data() {
    return {
      inputHistoryRunDaysCount: 0,
      showInputOverflowBanner: false,
      bannerContent: '',
      disableLaunchButton: true,
      disableClearAllButton: true,
      toggleSelectAllInputSetParam: true,
      showLoader: false,
      inputsArray: [],
      wfInputs: [],
      startDate: null,
      endDate: null,
      currentInputToEdit: {
        name: '',
        body: {},
      },
      totalInputSet: [],
      weekdayDataset: [
        {
          text: 'Sun',
          value: 'Sunday',
          enabled: false,
        },
        {
          text: 'Mon',
          value: 'Monday',
          enabled: true,
        },
        {
          text: 'Tue',
          value: 'Tuesday',
          enabled: true,
        },
        {
          text: 'Wed',
          value: 'Wednesday',
          enabled: true,
        },
        {
          text: 'Thu',
          value: 'Thursday',
          enabled: true,
        },
        {
          text: 'Fri',
          value: 'Friday',
          enabled: true,
        },
        {
          text: 'Sat',
          value: 'Saturday',
          enabled: false,
        },
      ],
      workflowUI: {},
      manager: {},
      currentBubble: {},
      maxTries: 0,
      runningJobs: [],
      goForLauchHistory: false,
      isWritePermissionError: false,
      validTotalinputs: [],
      invalidTotalinputs: [],
      paginationOptions: {
        firstItem: 1,
        pageSize: 50,
      },
      pageSize: [50, 100, 500, -1],
    };
  },
  computed: {
    ...mapGetters('workflowModule', [
      'getParamStatus',
      'getWorkflowFormulas',
      'getEditableWorkflowDetails',
      'getEditableWorkflowUI',
      'getEditableParamSet',
      'getEditableFormulae',
      'getEditableNewFormulae',
      'getEditableNodes',
      'getEditableLinks',
      'getEditableTargets',
      'getCreateNewWorkflowDetails',
      'getCreateNewWorkflowUI',
      'getCreateNewParamSet',
      'getCreateNewFormulae',
      'getallWorkflowsForName',
      'getCurrentWorkflowManager',
    ]),
    disableAddButton() {
      if (this.weekdayDataset.every(day => !day.enabled)) {
        return true;
      }
      if (this.startDate === null || this.endDate === null) {
        return true;
      }
      return this.startDate > this.endDate;
    },
    disableClearAllBtn() {
      return this.totalInputSet
        .some(ip => ['SUBMITTED', 'RUNNING'].includes(ip.inputStatus));
    },
    paginatedData() {
      // set new start and end values based on the updated pagination options
      const start = this.paginationOptions ? this.paginationOptions.firstItem - 1 : 0;
      const end = this.paginationOptions && this.paginationOptions.pageSize !== -1
        ? start + this.paginationOptions.pageSize
        : this.totalInputSet.length;

      // use the start and end values to return the visible page slice
      return this.totalInputSet.slice(start, end);
    },
  },
  mounted() {
    this.manager = this.getCurrentWorkflowManager;
    this.wfInputs = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels;
    this.wfInputs.forEach((input) => {
      input.text = input.name;
      input.value = input.name;
      input.id = input.id;
    });
    this.startDate = new Date(this.formatDate(this.getLastMonthDayFromToday().toISOString()));
    this.endDate = new Date(this.formatDate(this.getLastBusinessDay(new Date()).toISOString()));
    this.disableLaunchButton = this.totalInputSet.length === 0;
    this.disableClearAllButton = this.disableLaunchButton;
    this.currentBubble = this.manager._priv.bubbles[this.node.id];
  },
  methods: {
    formatDate(dateString) {
      const date = dateString.split('T')[0].split('-');
      return `${date[1]}/${+date[2]}/${date[0]}`;
    },
    getLastMonthDayFromToday() {
      const date = new Date();
      const currentMonth = date.getMonth();
      const lastMonthDate = new Date(date.getFullYear(), currentMonth - 1, date.getDate() + 1);
      return lastMonthDate;
    },
    getLastBusinessDay(currentDate) {
      const lastBusinessDay = new Date(currentDate);

      if ([2, 3, 4, 5, 6].includes(lastBusinessDay.getDay())) {
        lastBusinessDay.setDate(lastBusinessDay.getDate() - 1);
      } else if (lastBusinessDay.getDay() === 0) {
        lastBusinessDay.setDate(lastBusinessDay.getDate() - 2);
      } else if (lastBusinessDay.getDay() === 1) {
        lastBusinessDay.setDate(lastBusinessDay.getDate() - 3);
      }

      return lastBusinessDay;
    },
    selectWeekday(event, index) {
      this.weekdayDataset[index].enabled = !this.weekdayDataset[index].enabled;
    },
    getDatesBetween(startDate, endDate) {
      this.inputHistoryRunDaysCount = 0;
      function addDays(currentDate, days) {
        if (!currentDate) return;
        const date = new Date(currentDate.valueOf());
        date.setDate(date.getDate() + days);
        // eslint-disable-next-line consistent-return
        return date;
      }

      const weekday = [
        'Sunday',
        'Monday',
        'Tuesday',
        'Wednesday',
        'Thursday',
        'Friday',
        'Saturday',
      ];
      const dateArray = [];
      let currentDate = startDate;

      while (currentDate <= endDate) {
        const date = new Date(currentDate);

        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        dateArray.push({
          date: new Date(`${month}/${day}/${year}`),
          rawDateString: `${month}/${day}/${year}`,
          weekday: weekday[date.getDay()],
        });
        currentDate = addDays(currentDate, 1);
        this.inputHistoryRunDaysCount += 1;
      }

      return dateArray;
    },
    async fetchFeedDetails(inputs, startDate, endDate) {
      const feedNamesArr = [];
      const inputsInfo = {};
      const feedNameMap = {};
      this.disableLaunchButton = true;
      this.disableClearAllButton = true;

      for (let i = 0; i < this.inputsArray.length; i++) {
        const element = this.inputsArray[i];
        inputsInfo[this.inputsArray[i]] = {};

        for (let j = 0; j < this.manager.publishBubbles.length; j++) {
          const el = this.manager.publishBubbles[j];
          const { feed } = this.manager.inputMap[element][el];
          inputsInfo[this.inputsArray[i]].feed = feed;

          if (feed && !feedNamesArr.includes(feed)) {
            feedNamesArr.push(feed);
          }
        }
      }

      if (this.inputHistoryRunDaysCount > 90) {
        this.showInputOverflowBanner = true;
        this.bannerContent = 'Max allowed range is 90 days.';
        this.disableLaunchButton = true;
        this.disableClearAllButton = true;
        this.totalInputSet = [];
        this.goForLauchHistory = false;
        return;
      }

      await Promise.map(feedNamesArr, async (feedName) => {
        try {
          const response = await axios.get('api/feeds/entilements/writers', {
            params: {
              feedName,
            },
          });
          let flag = 0;

          for (let k = 0; k < response.data.length; k++) {
            if (response.data[k].username.toLowerCase() === getUserName().toLowerCase()) {
              flag = 1;
              break;
            }
          }

          if (flag === 1) {
            feedNameMap[feedName] = true;
          } else {
            feedNameMap[feedName] = false;
          }
        } catch (error) {
          console.error(`Error occurred while launching the history of jobID: ${feedName} ${error}`);
        }
      }, { concurrency: 2 }).then(async () => {
        const inputData = Object.keys(inputsInfo);
        for (let l = 0; l < inputData.length; l++) {
          inputsInfo[inputData[l]].isWritePermission = feedNameMap[inputsInfo[inputData[l]].feed];

          if (!inputsInfo[inputData[l]].isWritePermission) {
            this.showInputOverflowBanner = true;
            this.bannerContent = 'You lack write permission for one or more feeds configured in the highlighted inputs. Please remove these inputs by clicking on remove button to proceed with launching history.';
            this.isWritePermissionError = true;
          }
        }

        const datesArray = this.getDatesBetween(startDate, endDate);
        const selectedWeekdays = this.weekdayDataset
          .filter(weekday => weekday.enabled)
          .map(weekday => weekday.value);

        const filteredDatesArrayByWeekdays = datesArray.filter(d => selectedWeekdays.includes(d.weekday));
        let totalInputSet = [];

        for (let i = 0; i < inputs.length; i++) {
          for (let j = 0; j < filteredDatesArrayByWeekdays.length; j++) {
            if (
              this.totalInputSet.filter(
                input => input.inputName === inputs[i]
                    && input.inputDate === filteredDatesArrayByWeekdays[j].rawDateString,
              ).length === 0
            ) {
              if (inputsInfo[inputs[i]].isWritePermission) {
                this.validTotalinputs.push({
                  inputName: inputs[i],
                  inputPublishFeedName: inputsInfo[inputs[i]].feed,
                  isFeedWritePermission: inputsInfo[inputs[i]].isWritePermission,
                  inputDate: filteredDatesArrayByWeekdays[j].rawDateString,
                  inputStatus: 'NOT_SUBMITTED',
                  id: this.wfInputs.find(element => element.name === inputs[i]).id,
                });
              } else {
                this.invalidTotalinputs.push({
                  inputName: inputs[i],
                  inputPublishFeedName: inputsInfo[inputs[i]].feed,
                  isFeedWritePermission: inputsInfo[inputs[i]].isWritePermission,
                  inputDate: filteredDatesArrayByWeekdays[j].rawDateString,
                  inputStatus: 'NOT_SUBMITTED',
                  id: this.wfInputs.find(element => element.name === inputs[i]).id,
                });
              }
              totalInputSet = [...this.invalidTotalinputs, ...this.validTotalinputs];
            }
          }
        }

        this.paginationOptions = {
          firstItem: 1,
          pageSize: 50,
        };
        this.totalInputSet.push(...totalInputSet);
        this.disableLaunchButton = this.totalInputSet.length === 0;
        this.disableClearAllButton = this.disableLaunchButton;
        // this.showInputOverflowBanner = false;
        // this.bannerContent = '';
        this.goForLauchHistory = false;
      });
    },
    async generateInputSetForHistoricalRun(inputs, startDate, endDate, isDisabled) {
      if (isDisabled) {
        return;
      }
      this.clearInputSet();
      this.goForLauchHistory = true;
      await this.fetchFeedDetails(inputs, startDate, endDate);
    },
    removeNotPermittedInputs() {
      this.totalInputSet = this.validTotalinputs;
      this.isWritePermissionError = false;
      this.showInputOverflowBanner = false;
      this.bannerContent = '';
    },
    deleteScheduledJob(index) {
      this.totalInputSet = [
        ...this.totalInputSet.slice(0, index),
        ...this.totalInputSet.slice(index + 1),
      ];
      this.disableLaunchButton = this.totalInputSet.length === 0;
      this.disableClearAllButton = this.disableLaunchButton;
    },
    toggleAllInputSet() {
      if (this.toggleSelectAllInputSetParam) this.inputsArray = this.wfInputs.map(wfIp => wfIp.text);
      else this.inputsArray = [];
      this.toggleSelectAllInputSetParam = !this.toggleSelectAllInputSetParam;
    },
    clearInputSet() {
      this.$set(this.totalInputSet, []);
      this.$set(this.invalidTotalinputs, []);
      this.$set(this.validTotalinputs, []);
      this.totalInputSet = [];
      this.invalidTotalinputs = [];
      this.validTotalinputs = [];
      this.disableLaunchButton = true;
      this.disableClearAllButton = true;
      this.showInputOverflowBanner = false;
    },
    async refreshJobStatus() {
      await Promise.map(this.maxTries === 0 ? this.totalInputSet : this.runningJobs, input => axios.get(`/api/workflows/jobs/${encodeURIComponent(input.jobId)}`).then((response, request) => {
        const jobStatus = response.data;
        if (jobStatus.length <= 1) {
          input.inputStatus = 'RUNNING';
          this.runningJobs = this.runningJobs.filter(obj => obj.jobId !== input.jobId);
          this.runningJobs.push(input);
        } else if (jobStatus.some(job => job.status === 'NO_NEW_DATA')) {
          input.inputStatus = 'NO_NEW_DATA';
          this.runningJobs = this.runningJobs.filter(obj => obj.jobId !== input.jobId);
        } else if (jobStatus.some(job => job.phase === 'ts')) {
          input.inputStatus = 'COMPLETED';
          this.runningJobs = this.runningJobs.filter(obj => obj.jobId !== input.jobId);
        } else if (jobStatus.filter(job => job.status === 'COMPLETED').length < 5) {
          input.inputStatus = 'RUNNING';
          this.runningJobs = this.runningJobs.filter(obj => obj.jobId !== input.jobId);
          this.runningJobs.push(input);
        } else {
          input.inputStatus = 'NOT_AVAILABLE';
        }
      }).catch(() => console.error(`Error occured while launching the history of jobID: ${ input.jobId}`)), { concurrency: 2 }).then(() => {
        if (this.runningJobs.length > 0 && this.maxTries < 3) {
          this.maxTries++;
          this.refreshJobStatus();
        }
      });
    },
    submitJobs(isDisabled) {
      if (isDisabled) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.workflowUI = this.getEditableWorkflowDetails.workflowUI;
      const payload = { formulaUuid: this.node.data.formula_id, timeZone: this.workflowUI.timeZone, runs: [] };
      const dataSetName = _getSavingDataSetName(this.currentBubble);
      const isMultiSave = (_getSaveBubbles(this.currentBubble).length > 1);
      const feedKey = ((!isMultiSave && !isVoid(dataSetName))
        ? `${PARAM_PREFIX_DATASET + dataSetName }.${DATASET_SUFFIX_FEED}`
        : null
      );

      payload.runs = this.totalInputSet.map((jobs) => {
        const obj = {};
        let day = new Date(jobs.inputDate).getDate();
        let month = new Date(jobs.inputDate).getMonth() + 1;
        const year = new Date(jobs.inputDate).getFullYear();
        day = day > 9 ? `${day}` : `0${day}`;
        month = month > 9 ? `${month}` : `0${month}`;

        obj.runDate = `${year}-${month}-${day}`;
        obj.parameterSetId = jobs.id;

        if (typeof dataSetName === 'string') {
          if (feedKey !== null) {
            obj.feed = this.workflowData.workFlowJobModel.parameterSetGroupModel.parameterSetModels.find(psm => psm.id === jobs.id).parameterModels.find(pm => pm.propKey === feedKey).propValue;
          }
        }

        return obj;
      });
      this.disableLaunchButton = true;
      if (!isMultiSave) {
        this.showLoader = true;
      }
      axios.post(`/api/workflows/${this.workflow.id}/jobs`, payload).then((response) => {
        const submittedJobs = response.data.runs;
        if (!isMultiSave) {
          submittedJobs.forEach((job) => {
            const [year, month, date] = job.runDate.split('-');
            const runDate = `${+month}/${+date}/${year}`;
            const inputJob = this.totalInputSet
              .find(input => input.id === job.parameterSetId.toString() && runDate === input.inputDate);
            inputJob.jobId = job.jobId;
            inputJob.inputStatus = 'SUBMITTED';
          });
          this.refreshJobStatus();
        }
      }).finally(() => {
        if (!isMultiSave) {
          this.showLoader = false;
        }
      });
    },
    handlePageChange(paginationOptions) {
      // on page change, retrieve the new pagination options from the pagination component
      this.paginationOptions = paginationOptions;
    },
  },
};
</script>

  <style>
  .debug-line {
    /* debug */
    border: 2px solid red;
  }

  .launch-history-inputs-section {
    padding: 0.5rem;
  }

  .launch-history-dates-section {
    padding: 0.5rem;
  }

  .launch-history-input-box {
    padding: 0.3rem;
  }

  .launch-history-add-btn {
    margin-left: 0.5rem;
  }

  .launch-history-jobs-section {
    padding: 0.5rem;
    margin-top: 0rem !important;
  }

  .launch-history-jobs-list {
    list-style: none;
    padding: 0;
    margin: 0;
    /* overflow-y: scroll; */
  }

  .launch-history-jobs-section {
    padding: 0.5rem;
  }

  .launch-history-job-list-item {
    background: #dad9d9;
    padding: 0.3rem;
  }

  .launch-history-job {
    display: flex;
    justify-content: space-between;
    align-content: center;
    flex-wrap: nowrap;
    align-items: center;
  }

  .launch-history-weekdays-btn-group {
    margin-top: -0.7rem;
  }

  .launch-history-jobs-action-button-section {
    float: right;
  }

  .launch-history-jobs-action-button-section > div > button:nth-child(1) {
    margin-right: 0.5rem;
  }

  .launch-history-table {
    height: 30vh;
    overflow-y: auto;
    overflow-x: hidden;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .highlight-red {
    background-color: #ffe5e5;
  }
  .highlight-green {
    background-color: #e5f7eb;
  }
  .mds-banner___Mcd-ui .mds-banner__body___Mcd-ui {
    max-width: 100%;
  }
  </style>
