<!-- eslint-disable max-len -->
<template>
  <div
    class="wf-edit-action_"
  >
    <!-- eslint-disable-next-line vue/no-lone-template -->
    <template>
      <mds-notification-container v-if="showFormulaValidationBanner">
        <mds-notification
          key="error-tinted"
          variation="error"
          title="Formula Validation Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="showFormulaValidationBanner=false"
        >
          {{ formulaValidationErrorMessage }}
        </mds-notification>
      </mds-notification-container>
      <mds-notification-container v-if="errorMessage">
        <mds-notification
          key="error-tinted"
          visible
          variation="error"
          title="Error"
          tinted
          :dismiss-delay="3000"
          @mds-notification-dismissed="dissmissError"
        >
          <div v-html="errorMessage" />
        </mds-notification>
      </mds-notification-container>
      <mds-loader
        v-if="showLoader"
        size="small"
        aria-label="Small Loader"
      />
      <div v-else-if="!showErrorMsg">
        <div v-if="!showAdvanced"
          :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
        >
          <mds-input
            v-model="currentInputToEdit.data.bubbleName"
            label="Bubble Name: "
            style="margin-top: 0.5rem;"
            :class="isNewNode === 'false' || isNewNode === false ? 'disabled' : ''"
            @focusin="nameError = false"
          />
          <span
            v-if="nameError"
            id="bubbleName"
            class="mds-form__field-error"
            role="alert"
          >
            <span
              class="mds-form__field-error-text"
              style="margin-bottom: 10px;"
            >
              Bubble name cannot be empty.
            </span>
          </span>
          <mds-input
            v-model="currentInputToEdit.data.bubbleDescr"
            style="margin-top: 0.5rem;"
            label="Description: "
            placeholder="Optional"
          />
          <mds-notification-container v-if="showFormulaEmptyBanner">
            <!-- Tinted -->
            <mds-notification
              key="error-tinted"
              variation="error"
              title="Error"
              tinted
              :dismiss-delay="3000"
              @mds-notification-dismissed="showFormulaEmptyBanner=false"
            >
              Cannot save empty formula.
            </mds-notification>
          </mds-notification-container>
          <div>
            <mds-row>
              <mds-col>
                <h3
                  class="stp-table-lable"
                  style="margin-top: 1rem !important;"
                >
                  {{ showDeclaredVarsTable ? 'Already Declared Variables:' : 'Steps:' }}
                </h3>
                <div
                  v-if="!showDeclaredVarsTable"
                  class="stp-table"
                >
                  <mds-table
                    right-aligned
                    row-hover
                  >
                    <mds-tbody>
                      <mds-tr
                        v-for="(dn, index) in dataNodeSet"
                        :key="index"
                        style="text-align: center !important;"
                      >
                        <mds-td><b>{{ dn.name() }}</b></mds-td>
                        <mds-td>
                          {{ formulaNodeTypeConstants[dn.type()._name] }}
                        </mds-td>
                        <mds-td>
                          <mds-button
                            :disabled="!showViewFormulaNodeButton"
                            variation="primary"
                            @click.prevent="viewFormulaVariableConfig(index, !showViewFormulaNodeButton)"
                          >
                            View / Edit
                          </mds-button>
                        </mds-td>
                        <mds-td>
                          <mds-button
                            variation="secondary"
                            :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
                            @click.prevent="showPreviewResults(index)"
                          >
                            Preview
                          </mds-button>
                        </mds-td>
                        <mds-td>
                          <mds-button
                            variation="secondary"
                            icon-right="remove"
                            :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
                            @click.stop="removeFormulaVariable(index)"
                          >
                            Remove
                          </mds-button>
                        </mds-td>
                      </mds-tr>
                    </mds-tbody>
                  </mds-table>
                </div>
                <div
                  v-if="showDeclaredVarsTable"
                  class="stp-table"
                >
                  <mds-table
                    right-aligned
                  >
                    <mds-thead>
                      <mds-th> Variable </mds-th>
                      <mds-th> DataType </mds-th>
                      <mds-th> Type </mds-th>
                    </mds-thead>
                    <mds-tbody>
                      <mds-tr
                        v-for="(dv, index) in declaredVars"
                        :key="index"
                      >
                        <mds-td><b>{{ dv.varName }}</b></mds-td>
                        <mds-td>
                          {{ formulaNodeTypeConstants[dv.varType] }}
                        </mds-td>
                        <mds-td>
                          {{ dv.bubbleType.toUpperCase() }}
                        </mds-td>
                      </mds-tr>
                    </mds-tbody>
                  </mds-table>
                </div>
                <div
                  v-if="addNewVariableErrorMsg.length > 0"
                  style="margin-top: 1rem;"
                >
                  <div
                    v-for="(msg, idx) in addNewVariableErrorMsg"
                    :key="idx"
                    class="new-variable-error-msg"
                  >
                    {{ msg }}
                  </div>
                </div>
              </mds-col>
              <mds-col style="margin-top: 1rem;">
                <div v-if="displayNew">
                  <mds-combo-box
                    v-model="inputToAdd"
                    label="What"
                    :data-set="inputToAddDataset"
                    :disabled="disableWhatField"
                  />
                  <div v-if="inputToAdd[0] === 'forward_curve'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.forward_curve.variableName"
                      label="Variable name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.forward_curve.product"
                      label="Product"
                      :data-set="productVarDataset"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.forward_curve.delivery"
                      label="Delivery"
                      :data-set="forwardCurveDeliveryTypeDropdown"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.forward_curve.method"
                      label="Method"
                      :data-set="[{text: 'Plain/Arbitrage', value: 'Plain/Arbitrage'},{text: 'Arbitrage-free', value: 'Arbitrage-free'}]"
                    />
                    <div v-if="currentInputToEdit.data.steps.forward_curve.method[0] === 'Arbitrage-free'">
                      <mds-checkbox
                        style="margin-top: 0.5rem;"
                        :checked="currentInputToEdit.data.steps.forward_curve.arbitrageFree.basic"
                        value="accept"
                        @change="currentInputToEdit.data.steps.forward_curve.arbitrageFree.basic = !currentInputToEdit.data.steps.forward_curve.arbitrageFree.basic"
                      >
                        Basic
                      </mds-checkbox>
                    </div>
                    <mds-checkbox
                      style="margin-top: 0.5rem;"
                      :checked="currentInputToEdit.data.steps.forward_curve.includeNans"
                      value="accept"
                      @change="currentInputToEdit.data.steps.forward_curve.includeNans = !currentInputToEdit.data.steps.forward_curve.includeNans"
                    >
                      Include NaNs
                    </mds-checkbox>
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.forward_curve.curveDate"
                      label="Curve Date:"
                      :data-set="[{text: 'Today, minus days', value: 'today-minus'},{text: 'Fixed date', value: 'fixed-date'}]"
                      style="margin-top: 0.5rem;"
                    />
                    <div v-if="currentInputToEdit.data.steps.forward_curve.curveDate[0] === 'today-minus'">
                      <mds-input
                        v-model="currentInputToEdit.data.steps.forward_curve.curveDaysBackValue"
                        label="Minus days:"
                        type="number"
                        style="margin-top: 0.5rem;"
                      />
                      <mds-checkbox
                        style="margin-top: 0.5rem;"
                        :checked="currentInputToEdit.data.steps.forward_curve.skipWeekends"
                        value="accept"
                        @change="currentInputToEdit.data.steps.forward_curve.skipWeekends = !currentInputToEdit.data.steps.forward_curve.skipWeekends"
                      >
                        Skip Weekends
                      </mds-checkbox>
                    </div>
                    <div v-else>
                      <mds-date-picker
                        v-model="currentInputToEdit.data.steps.forward_curve.curveFixedDate"
                        label="Date"
                        :min-max-dates="{min: new Date(1990, 0, 1), max: new Date(2025, 8, 15)}"
                        :labels="{month: 'short'}"
                        :date-format="{month: 'short'}"
                      />
                    </div>
                  </div>
                  <div v-if="inputToAdd[0] === 'time_series'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.time_series.variableName"
                      label="Variable name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.time_series.product"
                      label="Product:"
                      :data-set="productVarDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-row style="margin-bottom: 0.5rem;">
                      <mds-col>
                        <mds-input
                          v-model="currentInputToEdit.data.steps.time_series.howFarBack"
                          label="How far back:"
                          type="number"
                        />
                      </mds-col>
                      <mds-col>
                        <mds-combo-box
                          v-model="currentInputToEdit.data.steps.time_series.time"
                          label="Time:"
                          :data-set="timeSeriesTimeDataset"
                        />
                      </mds-col>
                    </mds-row>
                    <mds-checkbox
                      :checked="currentInputToEdit.data.steps.time_series.intradayData"
                      value="accept"
                      @change="currentInputToEdit.data.steps.time_series.intradayData = !currentInputToEdit.data.steps.time_series.intradayData"
                    >
                      Intraday Data
                    </mds-checkbox>
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.time_series.timeZone"
                      label="Timezone:"
                      :data-set="timeZoneDataset"
                      :disabled="!currentInputToEdit.data.steps.time_series.intradayData"
                      style="margin-top: 0.5rem;"
                    />
                  </div>
                  <div v-if="inputToAdd[0] === 'missing_data_qa'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.missing_data_qa.variableName"
                      label="QA Name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.missing_data_qa.curve"
                      label="Curve:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-input
                      v-model="currentInputToEdit.data.steps.missing_data_qa.contains"
                      label="Contains atleast numeric values:"
                      type="number"
                      placeholder="Enter Numeric Values"
                    />
                    <mds-textarea
                      v-model="currentInputToEdit.data.steps.missing_data_qa.customMessage"
                      label="Custom Message:"
                      placeholder="Optional text to append to the error message (for when this validation fails.)"
                      size="medium"
                    />
                  </div>
                  <div v-if="inputToAdd[0] === 'spike_qa'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.spike_qa.variableName"
                      label="QA Name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.spike_qa.curve"
                      label="Curve:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-input
                      v-model="currentInputToEdit.data.steps.spike_qa.spikeValue"
                      label="Spike Value:"
                      type="number"
                      placeholder="Enter Postive Numeric Values (percentage are allowed)"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.spike_qa.comparison"
                      label="Comparison:"
                      :data-set="[{text: 'Side-by-side (Two curves)', value: 'side_by_side'}, {text: 'Linearity (One curve)', value: 'linearity'}]"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-if="currentInputToEdit.data.steps.spike_qa.comparison[0] === 'side_by_side'"
                      v-model="currentInputToEdit.data.steps.spike_qa.compareWith"
                      label="Compare With:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-textarea
                      v-model="currentInputToEdit.data.steps.spike_qa.customMessage"
                      label="Custom Message:"
                      placeholder="Optional text to append to the error message (for when this validation fails.)"
                      size="medium"
                    />
                  </div>
                  <div v-if="inputToAdd[0] === 'in_range_qa'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.in_range_qa.variableName"
                      label="QA Name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.in_range_qa.curve"
                      label="Curve:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-input
                      v-model="currentInputToEdit.data.steps.in_range_qa.lowest"
                      label="Lowest:"
                      type="number"
                      placeholder="Enter Numeric Values"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-input
                      v-model="currentInputToEdit.data.steps.in_range_qa.highest"
                      label="Highest:"
                      type="number"
                      placeholder="Enter Numeric Values"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-textarea
                      v-model="currentInputToEdit.data.steps.in_range_qa.customMessage"
                      label="Custom Message:"
                      placeholder="Optional text to append to the error message (for when this validation fails.)"
                      size="medium"
                    />
                  </div>
                  <div v-if="inputToAdd[0] === 'merge'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.merge.variableName"
                      label="Variable name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-field-set
                      variation="radio-group"
                      legend=""
                    >
                      <div>Align side-by-side, add columns:</div>
                      <div style="margin-left: 1rem;">
                        <mds-radio-button
                          v-model="currentInputToEdit.data.steps.merge.func"
                          name="Union"
                          value="union"
                          label="Union - all dates"
                        />
                        <mds-radio-button
                          v-model="currentInputToEdit.data.steps.merge.func"
                          name="Intersection"
                          value="intersection"
                          label="Intersection - only dates found in all curves"
                        />
                      </div>
                      <div>Extend, add dates:</div>
                      <div style="margin-left: 1rem;">
                        <mds-radio-button
                          v-model="currentInputToEdit.data.steps.merge.func"
                          name="Complement"
                          value="complement"
                          label="Complement - preserve first curve"
                        />
                        <mds-radio-button
                          v-model="currentInputToEdit.data.steps.merge.func"
                          name="Overwrite"
                          value="overwrite"
                          label="Overwrite - overwrite first curve"
                        />
                      </div>
                    </mds-field-set>
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.merge.intoCurve"
                      label="Into:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.merge.mergeCurve"
                      label="Merge:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-row>
                      <mds-col>
                        <mds-combo-box
                          v-model="additionalCurve"
                          label="Additional Curves to Merge:"
                          :data-set="curvesDataset"
                          style="margin-top: 0.5rem;"
                        />
                      </mds-col>
                      <mds-col>
                        <mds-button
                          variation="secondary"
                          style="margin-top: 2.25rem;"
                          @click.prevent="addCurveToMerge(additionalCurve)"
                        >
                          Add Curve
                        </mds-button>
                      </mds-col>
                    </mds-row>
                    <div>
                      <div
                        v-for="(curve, index) in currentInputToEdit.data.steps.merge.additionalCurvesToMerge"
                        :key="index"
                        class="additional-curve-section"
                      >
                        <div class="additional-curve-name">
                          {{ curve }}
                        </div>
                        <div class="additional-curve-button">
                          <mds-button
                            variation="icon-only"
                            icon="remove"
                            type="button"
                            @click.prevent="removeCurveToMerge(index)"
                          >
                            Remove
                          </mds-button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="inputToAdd[0] === 'fill'">
                    <mds-input
                      v-model="currentInputToEdit.data.steps.fill.variableName"
                      label="Variable name:"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.fill.curve"
                      label="Curve:"
                      :data-set="curvesDataset"
                      style="margin-top: 0.5rem;"
                    />
                    <mds-combo-box
                      v-model="currentInputToEdit.data.steps.fill.direction"
                      label="Direction:"
                      :data-set="[{text: 'Forward', value: 'forward'},{text: 'Backward', value: 'backward'}]"
                      style="margin-top: 0.5rem;"
                    />
                  </div>
                  <mds-row style="margin-top: 1rem;">
                    <mds-col :cols="6">
                      <mds-button
                        variation="primary"
                        class="formula-action-button"
                        @click.prevent="addFormulaVariable()"
                      >
                        {{ viewFormulaConfig ? 'Save' : 'Add Step' }}
                      </mds-button>
                      <mds-button
                        variation="secondary"
                        class="formula-action-button"
                        style="margin-left: 2%"
                        @click.prevent="showNewVariables(false)"
                      >
                        Cancel
                      </mds-button>
                    </mds-col>
                  </mds-row>
                </div>
              </mds-col>
            </mds-row>

            <div class="btn-row">
              <mds-row style="margin-top: 1rem;">
                <mds-col>
                  <mds-row>
                    <mds-col v-if="!showDeclaredVarsTable">
                      <mds-button
                        variation="primary"
                        @click.prevent="showNewVariables(true)"
                      >
                        New
                      </mds-button>
                      <mds-button
                        variation="secondary"
                        style="margin-left: 2%"
                        :disabled="disableCopyVariable"
                        @click.prevent="copyVariable(disableCopyVariable)"
                      >
                        Copy
                      </mds-button>
                    </mds-col>
                    <mds-col style="display: flex; justify-content: end;">
                      <mds-button
                        :variation="showDeclaredVarsTable ? 'primary' : 'secondary'"
                        @click.prevent="toggleDeclaredVarsTable()"
                      >
                        Already Declared
                      </mds-button>
                    </mds-col>
                  </mds-row>
                </mds-col>
                <mds-col />
              </mds-row>
            </div>
          </div>
        </div>

        <div v-if="showAdvanced">
          <mds-layout-grid>
            <mds-row>
              <mds-col :cols="8">
                <mds-row
                  align-vertical="center"
                >
                  <mds-col :cols="3">
                    <h3>
                      <b>Formula Editor</b>
                    </h3>
                  </mds-col>
                  <mds-col :cols="9">
                    <mds-button-group
                      :content="buttonGroupContent"
                      aria-label="Theme"
                      style="margin: 8px; padding-left: 185px"
                      @mds-button-group-item-active="setActiveItem"
                    />
                  </mds-col>
                </mds-row>
                <mds-row>
                  <div
                    id="formula-editor"
                    style="height: 600px; width: 100%;"
                  />
                </mds-row>
              </mds-col>

              <mds-col :cols="4">
                <div class="documentation-link">
                  <a
                    href="./#/guides/workflow"
                    target="_blank"
                  >Documentation</a>
                </div>
                <h3
                  class="stp-table-lable"
                  style="margin-top: 2rem;"
                >
                  Variables available at this point:
                </h3>
                <div class="vars-available">
                  <mds-table>
                    <mds-tbody>
                      <mds-tr
                        v-for="(vItem, idx) in varsAtThisPointDataset"
                        :key="idx"
                      >
                        <mds-td><b>{{ vItem.varName }}</b></mds-td>
                        <mds-td>{{ vItem.varType }}</mds-td>
                        <mds-td>{{ vItem.varDesc }}</mds-td>
                      </mds-tr>
                    </mds-tbody>
                  </mds-table>
                </div>
              </mds-col>
            </mds-row>
          </mds-layout-grid>
          <mds-notification-container v-if="showFormulaEmptyBanner">
            <!-- Tinted -->
            <mds-notification
              key="error-tinted"
              variation="error"
              title="Error"
              tinted
              :dismiss-delay="3000"
              @mds-notification-dismissed="showFormulaEmptyBanner=false"
            >
              Cannot save empty formula.
            </mds-notification>
          </mds-notification-container>
        </div>

        <hr style="margin-top: 4%;">
        <mds-row
          :class="isEditable === 'false' || isEditable === false ? 'disabled' : ''"
          style="margin-top: 1rem;"
        >
          <mds-col style="display: flex; justify-content: left;">
            <mds-button
              v-if="showAdvancedButton && !showDeclaredVarsTable"
              icon-right="angle-double-left"
              variation="primary"
              @click.stop="showAdvancedModeOptions = !showAdvancedModeOptions"
            >
              Advanced
            </mds-button>
          </mds-col>
          <mds-col style="display: flex; justify-content: right;">
            <mds-button
              variation="primary"
              @click.prevent="submitForm(false)"
            >
              Ok
            </mds-button>
          </mds-col>
        </mds-row>
        <div
          v-if="showAdvancedModeOptions"
          class="advanced-mode-controls-panel"
        >
          <div class="line">
            UI controls are disabled when switching to Advanced mode.
          </div>
          <div
            class="advanced-mode-controls-panel-buttons"
          >
            <mds-button
              variation="primary"
              @click.stop="showAdvancedEditorTab()"
            >
              Proceed
            </mds-button>
            <mds-button
              variation="secondary"
              @click.stop="showAdvancedModeOptions=false"
            >
              Cancel
            </mds-button>
          </div>
        </div>
      </div>
      <div v-else>
        <mds-empty-state
          title="Nothing to edit"
          message="Please connect any Data or publish node to this node."
          size="large"
          style="margin: auto;"
        />
      </div>
      <div>
        <mds-dialog
          v-if="showFormulaSaveModalFlag"
          id="formula-save-dialog"
          v-model="showFormulaSaveModal"
          action-required
          title="Save Formula"
          size="medium"
          width="500px"
        >
          <hr class="horizontal-bar">
          <div
            style="height: 40px;"
            v-html="`Do you want to save the changes?`"
          />
          <template #mds-dialog-actions-right>
            <mds-button-container right-aligned>
              <mds-button
                type="button"
                variation="secondary"
                @click="closeFormulaSaveModal"
              >
                Cancel
              </mds-button>
              <mds-button
                type="button"
                variation="primary"
                :loading="showSaveButtonLoader"
                @click="submitForm(true)"
              >
                Yes, Proceed
              </mds-button>
            </mds-button-container>
          </template>
        </mds-dialog>
      </div>
    </template>
  </div>
</template>

<script>
import {
  MdsTable, MdsTbody, MdsTd, MdsTh, MdsThead, MdsTr,
} from '@mds/data-table';
import MdsInput from '@mds/input';
import { mapActions, mapGetters } from 'vuex';
import MdsDialog from '@mds/dialog';
import { MdsButton, MdsButtonContainer } from '@mds/button';
import MdsTextarea from '@mds/textarea';
import { MdsNotification, MdsNotificationContainer } from '@mds/notification';
import MdsEmptyState from '@mds/empty-state';
import MdsRadioButton from '@mds/radio-button';
import MdsFieldSet from '@mds/fieldset';
import { MdsLayoutGrid, MdsRow, MdsCol } from '@mds/layout-grid';
import MdsComboBox from '@mds/combo-box';
import MdsDatePicker from '@mds/date-picker';
import Cookies from 'js-cookie';
import loader from '@monaco-editor/loader';
import _ from 'lodash';
import MdsButtonGroup from '@mds/button-group';
import FormulaGenerator from '../../../scripts/FormulaGenerator';
import parseFormulaFromConfig from '../../../scripts/FormulaParser';
import reverseParseConfigFromFormula from '../../../scripts/FormulaReverseParser';
import formulaNodeValidator from '../../../scripts/QANodeValidator';
import timeZone from '../../../constants/timezone';
import qaEditActionsConstants from '../../../constants/qaEditActions';
import {
  _getFullSavedFormulaCode, _getDefaultFormulaCode, _getVarList, _isFreeformFormula, _listFormulaSteps,
  _saveFormulaCodeInBubble, _formulaSection, _getValidatedUserCode, _getValidatedFreeformCode, _getFirstFormulaError,
} from '../../../scripts/utils/Manager';
import MpFormulaStep, {
  MpFormulaStepType as StepType,
  _safeLang,
} from '../../../scripts/utils/mpfstep/mpfstep';

import global from '../../../../../assets/js/globals';
import lim from '../../../../../assets/js/lim';

import commonUtils from '../../../scripts/commonUtils';

const EOL = '\n';

export default {
  name: 'WorkflowQAEditActions',
  components: {
    MdsDatePicker,
    MdsTable,
    MdsTbody,
    MdsTr,
    MdsTd,
    MdsTh,
    MdsThead,
    MdsLayoutGrid,
    MdsRow,
    MdsCol,
    MdsButton,
    MdsInput,
    MdsTextarea,
    MdsEmptyState,
    MdsComboBox,
    MdsRadioButton,
    MdsFieldSet,
    MdsNotification,
    MdsNotificationContainer,
    MdsDialog,
    MdsButtonContainer,
    MdsButtonGroup,
  },
  props: {
    node: {
      type: Object,
      default: null,
    },
    workflowData: {
      type: Object,
      default: null,
    },
    nodeVarName: {
      type: String,
      default: null,
    },
    formulas: {
      type: Array,
      default: null,
    },
    parameterSetData: {
      type: Object,
      default: null,
    },
    workflow: {
      type: Object,
      required: false,
      default: null,
    },
    isNewNode: {
      type: Boolean,
      required: false,
      default: false,
    },
    isEditable: {
      type: Boolean,
      required: false,
      default: false,
    },
    badFormulaBubbles: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      formulaValidationErrorMessage: '',
      showFormulaValidationBanner: false,
      lastSelectedInputToAdd: '',
      showFormulaEmptyBanner: false,
      disableCopyVariable: true,
      disableWhatField: false,
      showViewFormulaNodeButton: true,
      showLoader: false,
      showAdvancedButton: true,
      showAdvancedModeOptions: false,
      showAdvanced: false,
      inputToAdd: [],
      currentNodeFormula: '',
      currentNodeFormulaObj: {},
      displayNew: false,
      showErrorMsg: false,
      showError: false,
      updatedFormulaObj: {},
      nameError: false,
      formulaGenerator: null,
      formulaBody: '',
      productVarDataset: [],
      inputToAddDataset: qaEditActionsConstants.inputToAddDataset,
      currentInputToEdit: {
        data: {
          bubbleName: '',
          bubbleDescr: '',
        },
      },
      additionalCurve: '',
      timeZoneDataset: timeZone.map(tz => ({ text: tz, value: tz })),
      forwardCurveDeliveryTypeDropdown: Object.keys(qaEditActionsConstants.forwardCurveUI.deliveryTypeMap)
        .map(k => ({ text: qaEditActionsConstants.forwardCurveUI.deliveryTypeMap[k], value: k })),
      mergeUIFunctionDataset: qaEditActionsConstants.mergeUI.functionDropdownData.map(ds => ({ text: ds[0], value: ds[1] })),
      curvesDataset: [],
      timeSeriesTimeDataset: qaEditActionsConstants.timeSeriesUI.timeDataset,
      formulaNodeTypeConstants: qaEditActionsConstants.formulaNodeTypesForUI,
      formulaNodeDataset: JSON.parse(JSON.stringify(qaEditActionsConstants.formulaNodeTemplateObject)),
      varsAtThisPointDataset: [],
      preFormulaArray: [],
      dataNodeSet: [],
      addNewVariableErrorMsg: [],
      editVariableIndex: -1,
      currentIndex: -1,
      manager: {},
      currentBubble: {},
      defaultCode: '',
      varList: [],
      steps: [],
      declaredVars: [],
      showDeclaredVarsTable: false,
      errorMessage: '',
      showFormulaSaveModal: false,
      showFormulaSaveModalFlag: false,
      showSaveButtonLoader: false,
      viewFormulaConfig: false,
      editor: {},
      buttonGroupContent: [
        {
          id: '1', text: 'Light', active: true, val: 'vs',
        },
        {
          id: '2', text: 'Dark', active: false, val: 'vs-dark',
        },
        {
          id: '3', text: 'Contrast Dark', active: false, val: 'hc-black',
        },
        {
          id: '4', text: 'Contrast Light', active: false, val: 'hc-light',
        },
      ],
      editorTheme: 'vs',
    };
  },
  computed: {
    ...mapGetters('workflowModule', ['getCurrentWorkflowManager', 'getFormulaDataNodeSet', 'getUnsavedFormula']),
  },
  watch: {
    formulaBody(newFormula) {
      this.addUnsavedFormula(newFormula);
    },
    dataNodeSet(newSteps) {
      this.addUnsavedFormula(this.generateFormulaForStepType());
    },
    editorTheme(newValue) {
      localStorage.editorTheme = newValue;
      this.buttonGroupContent.forEach((item, index) => {
        if (item.val === this.editorTheme) {
          this.$set(this.buttonGroupContent[index], 'active', true);
        } else {
          this.$set(this.buttonGroupContent[index], 'active', false);
        }
      });
    },
    showAdvanced(value) {
      if (value) {
        loader.init().then((monaco) => {
          const libUri = 'js/lim';
          const parsedAvailableVars = this.varsAtThisPointDataset.map((v) => {
            let varCode = '/**\n';
            if (Object.hasOwn(v, '_descr')) {
              varCode += `
                * ${v._descr}\n`;
            }
            if (Object.hasOwn(v, '_dt') && Object.hasOwn(v._dt, '_val')) {
              varCode += `* @{type} ${v._dt._val}\n`;
            }
            if (Object.hasOwn(v, '_code') && v._code) {
              varCode += `*/\n
              var ${v._name} = ${v._code};\n`;
            } else {
              varCode += `*/\n
              var ${v._name};\n`;
            }
            return varCode;
          });
          const lib = [lim, global, ...parsedAvailableVars].join('\n');
          monaco.editor.getModels().forEach(model => model.dispose());
          monaco.languages.typescript.javascriptDefaults.addExtraLib(lib, libUri);
          monaco.editor.createModel(lib, 'javscript', monaco.Uri.parse(libUri));

          const editor = monaco.editor.create(
            document.getElementById('formula-editor'), {
              value: this.formulaBody.trim() + EOL,
              language: 'javascript',
              automaticLayout: true,
              wordWrap: 'bounded',
              theme: this.editorTheme,
              readOnly: !this.isEditable,
            },
          );

          // Function to set the suggestion widget position
          function setSuggestionPosition() {
            setTimeout(() => {
              const suggestWidget = document.querySelector('.monaco-editor .suggest-widget');
              if (suggestWidget) {
                const cursorPosition = editor.getPosition();
                const cursorCoords = editor.getScrolledVisiblePosition(cursorPosition);
                suggestWidget.style.left = `${cursorCoords.left + cursorCoords.width }px`;
                suggestWidget.style.top = `${cursorCoords.top + cursorCoords.height }px`;
              }
            }, 0);
          }

          // Set suggestion widget position on content change and cursor move
          editor.onDidChangeModelContent(setSuggestionPosition);
          editor.onDidChangeCursorPosition(setSuggestionPosition);

          editor.getModel().onDidChangeContent((event) => {
            this.formulaBody = editor.getValue().trim();
            this.modifyFormula();
          });
          this.editor = editor;
        });
      }
    },
  },
  mounted() {
    if (localStorage.editorTheme) {
      this.editorTheme = localStorage.editorTheme;
    }
    this.init();
  },
  methods: {
    ...mapActions(
      'workflowModule',
      [
        'updateUserWorkflowsDiagram',
        'getWorkflowStatus',
        'getParamStatus',
        'updateWorkflowFormulas',
        'updateBubbleDataset',
        'setFormulaDataNodeSet',
        'addUnsavedFormula',
      ],
    ),
    setActiveItem(event) {
      this.buttonGroupContent.forEach((item) => {
        if (item.id === event.target.id) {
          item.active = true;
          this.editor._themeService.setTheme(item.val);
          this.editorTheme = item.val;
        } else {
          item.active = false;
        }
      });
    },
    loadData() {
      this.manager = this.getCurrentWorkflowManager;
      this.currentBubble = this.manager._priv.bubbles[this.node.id];
      const { formulas } = this.manager._priv;
      const uuid = this.currentBubble.data('formula_id');
      if (uuid !== null) {
        if (!Object.hasOwnProperty.call(formulas, uuid)) {
          throw new Error(`IllegalStateException: unregistered formula ID (${ uuid })`);
        }
        this.defaultCode = formulas[uuid].content;
      }
      if (this.getUnsavedFormula.length > 0) {
        this.defaultCode = this.getUnsavedFormula;
      }
      this.varList = _getVarList(this.currentBubble);
      if (_isFreeformFormula(this.defaultCode)) {
        this.showAdvanced = true;
        this.showAdvancedModeOptions = false;
        this.showAdvancedButton = false;
        this.formulaBody = this.defaultCode;
      } else {
        if (this.defaultCode === '' || this.defaultCode === null) {
          this.defaultCode = _getDefaultFormulaCode(this.currentBubble);
        }
        this.steps = _listFormulaSteps(this.defaultCode);
        this.dataNodeSet = this.steps;
      }
      if (!this.showAdvanced) {
        const formulaBubbleNodeIds = this.workflow.ui.bubbles
          .filter(b => b.type === 'qa' || b.type === 'formula')
          .filter(b => b.id !== this.node.id)
          .map(b => b.id);
        this.declaredVars = commonUtils.getAlreadyDeclaredVariables({
          manager: this.manager,
          nodeIds: formulaBubbleNodeIds,
        });
      }

      _getFirstFormulaError(this.manager._priv.bubbles[this.node.id], this.defaultCode, true, this);
    },
    toggleDeclaredVarsTable() {
      this.showDeclaredVarsTable = !this.showDeclaredVarsTable;
    },
    init() {
      this.currentInputToEdit = {
        data: {
          bubbleName: '',
          bubbleDescr: '',
          steps: {
            forward_curve: {
              variableName: '',
              product: [],
              delivery: [],
              method: [],
              arbitrageFree: {
                basic: false,
              },
              includeNans: false,
              curveDate: [],
              fixedDate: '',
              skipWeekends: false,
              curveDaysBackValue: 0,
              curveFixedDate: '',
            },
            time_series: {
              variableName: '',
              product: [],
              howFarBack: '',
              time: [],
              timeZone: ['Asia/Kolkata'],
              intradayData: false,
            },
            basic_math: {
              variableName: '',
              computation: '',
            },
            extrapolation: {
              method: [],
              variableName: '',
              curve: [],
              model: [],
              extend: [],
              cycleSize: '',
              cycleUpToDate: '',
            },
            merge: {
              additionalCurvesToMerge: [],
              variableName: '',
              func: '',
              intoCurve: [],
              mergeCurve: [],
            },
            fill: {
              variableName: '',
              curve: [],
              direction: [],
            },
            free_text: {
              variableName: '',
              code: '',
            },
            missing_data_qa: {
              variableName: '',
              curve: [],
              contains: '',
              customMessage: '',
            },
            spike_qa: {
              variableName: '',
              curve: [],
              spikeValue: '',
              comparison: [],
              compareWith: [],
              customMessage: '',
            },
            in_range_qa: {
              variableName: '',
              curve: [],
              lowest: '',
              highest: '',
              customMessage: '',
            },
          },
        },
      };
      try {
        this.loadData();
      } catch (error) {
        console.log(error);
      }
      this.variablesAtThisPointDataset = qaEditActionsConstants.variablesAtThisPointDataSet;
      this.currentInputToEdit.data.bubbleName = this.node.data.bubbleName || '';
      this.currentInputToEdit.data.bubbleDescr = this.node.data.bubbleDescrc || '';
      if (this.formulas.length > 0 && this.formulas.find(f => f.uuid === this.node.data.formula_id)) {
        this.currentNodeFormula = this.formulas.filter(f => f.uuid === this.node.data.formula_id)[0].formula || '';
        this.currentNodeFormulaObj = this.formulas.filter(f => f.uuid === this.node.data.formula_id)[0] || {};
      } else {
        this.currentNodeFormula = '';
        this.currentNodeFormulaObj = {};
      }

      this.formulaGenerator = new FormulaGenerator(
        this.workflow.ui,
        this.workflow.ui.single_vars,
        this.currentNodeFormula,
      );
      if (!this.showAdvanced) {
        this.formulaBody = this.formulaGenerator.formulaBodySanitised(this.currentNodeFormula) || '';
      }
      for (let i = 0; i < this.workflow.ui.datasets.length; i++) {
        const element = this.workflow.ui.datasets[i];
        if (this.manager._priv.bubbles[element.bubbleId]._type._val === 'data') {
          this.productVarDataset.push({
            text: `$${element.varName}`,
            value: `$${element.varName}`,
          });
        }
      }
      this.varsAtThisPointDataset = this.formulaGenerator
        .parseVariablesAtThisPoint(qaEditActionsConstants.variablesAtThisPointDataSet);

      if (this.formulaBody === '') {
        const dataBubbles = this.workflow.ui.bubbles.filter(b => b.type === 'data');

        for (let i = 0; i < dataBubbles.length; i++) {
          const currentBubble = dataBubbles[i];
          const { varName } = this.workflow.ui.datasets.filter(d => d.bubbleId === currentBubble.id)[0];
          if (currentBubble.props.enableCorrection) {
            this.preFormulaArray.push(parseFormulaFromConfig('time_series_corr', {
              ...currentBubble.props.correctionData, varName,
            }));
          }
        }

        this.formulaBody = this.preFormulaArray.join('\n');
      }
      this.setFormulaDataNodeSet({ nodeId: this.node.id, dataNodes: this.dataNodeSet });
    },
    addCurveToMerge(additionalCurve) {
      if (!additionalCurve) return;
      this.currentInputToEdit.data.steps.merge.additionalCurvesToMerge.push(additionalCurve[0]);
    },
    removeCurveToMerge(index) {
      this.currentInputToEdit.data.steps.merge.additionalCurvesToMerge = [
        ...this.currentInputToEdit.data.steps.merge.additionalCurvesToMerge.slice(0, index),
        ...this.currentInputToEdit.data.steps.merge.additionalCurvesToMerge.slice(index + 1),
      ];
    },
    transitToAdvance() {
      this.updatedFormula = this.steps.map((step) => {
        const name = `${step.name()}: ${_safeLang(step._type._name, step.type().valueOf())}`;
        return `// ${ name }${EOL}${step.codeOnly() }${EOL}`;
      }).join(EOL + EOL);
      this.formulaBody = this.updatedFormula;
      this.showAdvanced = true;
    },
    updateFormula() {
      if (this.showAdvanced) {
        return _getValidatedFreeformCode(this.currentBubble, null, this.formulaBody);
      }
      return this.generateFormulaForStepType();
    },
    saveFormula() {
      let objformula = {};
      const date = new Date();
      const nameDate = `${date.getFullYear()
        + (`0${ date.getMonth() + 1}`).slice(-2)
        // eslint-disable-next-line max-len
        + (`0${ date.getDate()}`).slice(-2)}T${ (`0${ date.getHours()}`).slice(-2) }${(`0${ date.getMinutes()}`).slice(-2) }${(`0${ date.getSeconds()}`).slice(-2)}`;
      const formulanumber = this.formulas.length;

      if (Cookies.get('userInfoVue') !== undefined) {
        this.userName = JSON.parse(Cookies.get('userInfoVue')).userName;
      }

      const code = this.updateFormula();

      if (!this.errorMessage) {
        this.updatedFormulaObj = code;
      } else {
        return null;
      }

      if (this.isNewNode) {
        objformula = {
          active: true,
          formula: this.updatedFormulaObj,
          insertedTime: new Date(`${new Date().toString().split('GMT')[0]} UTC`).toISOString(),
          name: `wfgen_${this.workflow.name}_${nameDate}_${formulanumber}`,
          system: false,
          type: 'JS',
          userName: this.userName,
          uuid: '',
          version: 1,
          nodeID: this.node.id,
          targetName: this.node.data.targetName,
          isNewFormula: this.isNewNode,
        };
      } else {
        objformula = this.currentNodeFormulaObj;
        objformula.formula = this.updatedFormulaObj;
        objformula.insertedTime = new Date(`${new Date().toString().split('GMT')[0]} UTC`).toISOString();
        objformula.name = `wfgen_${this.workflow.name}_${nameDate}_${formulanumber}`;
        objformula.version = this.currentNodeFormulaObj.version++;
      }
      this.$emit('diagramChanged');
      return objformula;
    },
    saveSection() {
      const saveInitComment = '/*&ANALYZE_SUSPEND SAVE*/\n\n';
      let saveVar = '';
      const saveEndComment = '\n\n/*&ANALYZE_RESUME SAVE*/\n';

      if (!this.isNewNode) {
        const remainingPart = this.currentNodeFormula.split(saveInitComment)[1];
        // eslint-disable-next-line prefer-destructuring
        saveVar = remainingPart.split(saveEndComment)[0];
      }
      return `${saveInitComment }${saveVar }${saveEndComment}`;
    },
    resetLastSelectedInputFields() {
      if (this.lastSelectedInputToAdd) {
        this.currentInputToEdit.data.steps[this.lastSelectedInputToAdd] = qaEditActionsConstants
          .formulaNodeTemplateObject[this.lastSelectedInputToAdd];
      }
      if (this.lastSelectedInputToAdd === 'time_series') {
        this.currentInputToEdit.data.steps.time_series.variableName = '';
      }
    },
    showNewVariables(bool) {
      this.resetLastSelectedInputFields();
      this.disableCopyVariable = true;
      this.disableWhatField = false;
      this.currentIndex = this.dataNodeSet.length;
      this.showViewFormulaNodeButton = true;
      this.displayNew = bool;
      this.viewFormulaConfig = false;
      this.editVariableIndex = -1;
      this.inputToAdd = [];
      this.curvesDataset = this.generateCurvesDropdownDataset();
    },
    copyVariable(isDisabled) {
      if (isDisabled) {
        return;
      }
      const dataNodeType = this.dataNodeSet[this.currentIndex]._type._name;
      this.inputToAdd = [dataNodeType];
      this.currentInputToEdit.data.steps[dataNodeType].variableName = '';
      this.disableCopyVariable = true;
      this.disableWhatField = true;
      this.currentIndex = this.dataNodeSet.length;
      this.showViewFormulaNodeButton = false;
      this.displayNew = true;
      this.viewFormulaConfig = false;
      this.editVariableIndex = -1;
      this.curvesDataset = this.generateCurvesDropdownDataset();
    },
    submitForm(isTabSwitch) {
      if (this.showAdvanced && !this.formulaBody) {
        this.showFormulaEmptyBanner = true;
        return;
      }
      if (!this.showAdvanced && this.dataNodeSet.length === 0) {
        this.showFormulaEmptyBanner = true;
        return;
      }
      if (this.showAdvanced && this.formulaBody) {
        const formulaSyntaxErrorMessage = commonUtils.checkFormulaSyntaxError(this.formulaBody);
        if (formulaSyntaxErrorMessage) {
          this.showFormulaValidationBanner = true;
          this.formulaValidationErrorMessage = formulaSyntaxErrorMessage;
          return;
        }
      }

      this.updatedFormulaObj = this.saveFormula();
      if (!this.updatedFormulaObj) {
        return;
      }
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.bubbleName = this.currentInputToEdit.data.bubbleName;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.bubbleDescr = this.currentInputToEdit.data.bubbleDescr;
      // eslint-disable-next-line vue/no-mutating-props
      this.node.data.dataNodeSet = this.dataNodeSet;
      _saveFormulaCodeInBubble(this.updatedFormulaObj.formula, this.currentBubble, this.manager,
        this.currentInputToEdit.data.bubbleName, this.currentInputToEdit.data.bubbleDescr, this, 'qaBubble');

      this.setFormulaDataNodeSet({ nodeId: this.node.id, dataNodes: this.dataNodeSet });
      this.dataNodeSet = this.getFormulaDataNodeSet(this.node.id);

      if (!isTabSwitch) {
        this.$emit('newQAFormulaAdded', this.updatedFormulaObj, this.node);
      } else {
        this.showFormulaSaveModal = false;
        this.showFormulaSaveModalFlag = false;
        this.$emit('formula-saved');
      }
    },
    braodcastFormulaErrorMsg() {
      this.$emit('gotFormulaError', this.badFormulaBubbles, this.errorMessage);
    },
    generateCurvesDropdownDataset() {
      const dataNodesVarSet = this.dataNodeSet.map(n => n._name);
      return dataNodesVarSet.slice(0, this.currentIndex).map(v => ({ text: v, value: v }));
    },
    getMpFormulaStepType(type) {
      switch (type) {
        case 'forward_curve':
          return StepType.FORWARD_CURVE;
        case 'time_series':
          return StepType.TIME_SERIES;
        case 'basic_math':
          return StepType.BASIC_MATH;
        case 'extrapolation':
          return StepType.EXTRAPOLATION;
        case 'merge':
          return StepType.MERGE;
        case 'fill':
          return StepType.FILL;
        case 'free_text':
          return StepType.FREE_TEXT;
        case 'missing_data_qa':
          return StepType.MISSING_DATA_QA;
        case 'spike_qa':
          return StepType.SPIKE_QA;
        case 'in_range_qa':
          return StepType.IN_RANGE_QA;
        default:
          return null;
      }
    },
    generateFormulaForStepType() {
      const code = this.dataNodeSet.map((step) => {
        const name = `${step.type().toString()
        } ${
          step.name()}`;
        return _formulaSection(name, step.scriptSnippet());
      }).join(EOL + EOL);
      return _getValidatedUserCode(this.currentBubble, code, null, null, null, this);
    },
    generateFormulaCodeFromSteps(steps = []) {
      const formulaCodeArray = [];

      steps.forEach((step) => {
        const code = step.codeOnly();
        formulaCodeArray.push([EOL, `// ${step.name()}: ${_safeLang(step._type._name, step.type().valueOf())}`, code].join(EOL));
      });

      const fullFormula = formulaCodeArray.join(EOL).trim();
      this.formulaBody = fullFormula;
    },
    addFormulaVariable() {
      const selectedInput = this.inputToAdd[0];
      this.lastSelectedInputToAdd = selectedInput;
      const formulaConfig = this.currentInputToEdit.data.steps[selectedInput];
      const [validationResult, errorMessages] = formulaNodeValidator(selectedInput, formulaConfig);

      if (validationResult) {
        this.addNewVariableErrorMsg = errorMessages;
        return;
      }

      if (this.viewFormulaConfig && this.editVariableIndex >= 0) {
        this.dataNodeSet[this.editVariableIndex] = new MpFormulaStep(
          this.getMpFormulaStepType(selectedInput),
          formulaConfig.variableName,
          parseFormulaFromConfig(selectedInput, formulaConfig),
        );

        this.viewFormulaConfig = false;
        this.editVariableIndex = -1;
        this.showNewVariables(false);
        this.addNewVariableErrorMsg = [];
        this.currentInputToEdit.data.steps[selectedInput] = qaEditActionsConstants.formulaNodeTemplateObject[selectedInput];
        this.modifyFormula();
        return;
      }

      if (this.dataNodeSet.filter(dn => dn.name() === formulaConfig.variableName).length === 0) {
        const dataNode = new MpFormulaStep(
          this.getMpFormulaStepType(selectedInput),
          formulaConfig.variableName,
          parseFormulaFromConfig(selectedInput, formulaConfig),
        );

        this.dataNodeSet.push(dataNode);
        this.showNewVariables(false);
        this.addNewVariableErrorMsg = [];
      } else {
        this.addNewVariableErrorMsg.push(`Variable ${formulaConfig.variableName} is already defined!`);
      }
      this.currentInputToEdit.data.steps[selectedInput] = qaEditActionsConstants.formulaNodeTemplateObject[selectedInput];
      this.modifyFormula();
    },
    removeFormulaVariable(index) {
      this.dataNodeSet = [
        ...this.dataNodeSet.slice(0, index),
        ...this.dataNodeSet.slice(index + 1),
      ];
      this.modifyFormula();
    },
    viewFormulaVariableConfig(index, isDisabled) {
      if (isDisabled) {
        return;
      }
      this.disableCopyVariable = false;
      this.disableWhatField = true;
      this.currentIndex = index;
      const dataNode = this.dataNodeSet[index];
      this.lastSelectedInputToAdd = dataNode.type()._name;

      if (['merge', 'fill', 'missing_data_qa', 'spike_qa', 'in_range_qa'].includes(dataNode.type()._name)) {
        this.curvesDataset = this.generateCurvesDropdownDataset();
      }

      const parseResult = reverseParseConfigFromFormula(dataNode);
      const config = {
        varName: parseResult.variableName,
        varType: dataNode.type()._name,
        formulaConfig: parseResult,
      };
      this.setVariablesForViewFormulaConfig(config, index);
    },
    showPreviewResults(index) {
      this.$emit('showPreviewResults', this.dataNodeSet[index]);
    },
    setVariablesForViewFormulaConfig(config, index) {
      this.inputToAdd = [];
      this.displayNew = true;
      this.viewFormulaConfig = true;
      this.viewFormulaConfigType = config.varType;
      this.currentInputToEdit.data.steps[config.varType] = config.formulaConfig;
      this.inputToAdd[0] = config.varType;
      this.editVariableIndex = index;
    },
    showAdvancedEditorTab() {
      if (this.showAdvancedModeOptions) {
        localStorage.setItem(`WF-${this.workflow.id}`, JSON.stringify({
          showAdvanced: true,
          showAdvancedModeOptions: false,
          showAdvancedButton: false,
        }));
        this.showAdvanced = true;
        this.showAdvancedModeOptions = false;
        this.showAdvancedButton = false;
        this.init();
        this.generateFormulaCodeFromSteps(this.dataNodeSet);
      }
    },
    dissmissError() {
      this.errorMessage = '';
    },
    modifyFormula() {
      this.$emit('formula-modified');
    },
    closeFormulaSaveModal() {
      this.steps = _listFormulaSteps(this.defaultCode);
      this.dataNodeSet = this.steps;
      this.setFormulaDataNodeSet({ nodeId: this.node.id, dataNodes: this.dataNodeSet });
      this.$emit('formula-save-cancle');
    },
  },
};
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&display=swap');

#fullscreen .stp-table {
    border: 3px solid #f0f0f0 !important;
    padding: 1rem;
    border-radius: 0 !important;
    height: 300px !important;
    width: auto !important;
    overflow: auto !important;
    overflow-y: scroll;
}
#fullscreen .mds-select___Mcd-ui {
    margin-top: 2% !important;
}
#fullscreen .btn-row {
    padding-top: 1%;
}

#fullscreen .stp-table td {
  padding: 0.5rem !important;
  cursor: pointer;
}
.wf-edit-action_ {
  height: 100%;
  overflow: auto;
  padding: 1rem;
}
.wf-edit-action_ form {
  height: 100%;
}
#fullscreen .mds-label___Mcd-ui {
  margin-bottom: 5px;
}
#fullscreen .mds-textarea___Mcd-ui.mds-textarea--large___Mcd-ui {
  font-family: 'Inconsolata', monospace !important;
  font-size: 18px;
  height: auto;
  resize: both;
  min-width:auto;
  width:100%;
}
.code-editor .code-area > textarea {
  color: black !important;
}
#fullscreen .disabled {
  pointer-events: none;
  cursor: no-drop;
  opacity: 0.5;
  display: block !important;
}
.additional-curve-section {
  display: flex;
  margin-top: 0.2rem;
}
.additional-curve-name {
  margin-left: .2rem;
  margin-top: .1rem;
}
.additional-curve-button {
  margin-left: .2rem;
}
.advanced-mode-controls-panel {
  padding-left: 0.5rem;
  margin-top: 0.5rem;
}
.advanced-mode-controls-panel-buttons {
  margin-left: -0.5rem;
}
.advanced-mode-controls-panel-buttons > button {
  margin: 0.3rem;
}
.new-variable-error-msg {
  color: red;
  font-size: 18px;
  margin-top: 0.3rem;
  margin-bottom: 0.3rem;
}
.contains-atleast-section {
  display: flex;
  justify-content: space-between;
}
.documentation-link a {
  color: #202020;
  font-weight: bold;
  text-decoration: underline;
  font-size: 1.1em;
  float: inline-end;
}
#fullscreen .disabled .stp-table {
  overflow-y: scroll !important;
  pointer-events: all;
}
</style>
